import React from "react";
import PropTypes from "prop-types";

const missingProvider = "You forgot to wrap your app in <AssetsConfigProvider>";

const AssetsConfigContext = React.createContext({
  get assetsUrl() {
    throw new Error(missingProvider);
  },
});

AssetsConfigContext.displayName = "AssetsConfigContext";

export function AssetsConfigProvider({ children, value }) {
  return (
    <AssetsConfigContext.Provider value={value}>
      {children}
    </AssetsConfigContext.Provider>
  );
}

export const useAssetsConfig = () => React.useContext(AssetsConfigContext);

AssetsConfigProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  value: PropTypes.shape({
    assetsUrl: PropTypes.string,
  }),
};
