import React from "react";
import PropTypes from "prop-types";
import Script from "next/script";

import BugsnagClient from "../../utils/bugsnag";
import { useUser } from "../user";

const missingBeaconProvider = "You forgot to wrap your app in <BeaconProvider>";

const BeaconContext = React.createContext({
  get beacon() {
    throw new Error(missingBeaconProvider);
  },
});

BeaconContext.displayName = "BeaconContext";

export function BeaconProvider({ children }) {
  const [beaconState, setBeaconState] = React.useState({
    beacon: null,
    error: null,
  });

  const onBeaconReady = () => {
    // Initialize Beacon
    window.Beacon = (method, options, data) => {
      window.Beacon.readyQueue.push({ method, options, data });
    };
    window.Beacon.readyQueue = [];
    window.Beacon("init", process.env.NEXT_PUBLIC_BEACON_SCRIPT_KEY);

    window.Beacon("on", "ready", () => {
      setBeaconState({ beacon: window.Beacon });
    });
  };

  return (
    <BeaconContext.Provider value={beaconState}>
      <Script
        id="helpscout-beacon"
        src="https://beacon-v2.helpscout.net"
        onLoad={onBeaconReady}
        onError={() => {
          setBeaconState({ error: "Beacon failed to load" });
          BugsnagClient.notify({
            name: "Beacon failed to load",
            message: `Helpscout beacon had an issue`,
          });
        }}
      />
      {children}
    </BeaconContext.Provider>
  );
}

export function useBeacon() {
  const { beacon, error } = React.useContext(BeaconContext);
  const { user } = useUser();

  React.useEffect(() => {
    if (user && user.isLoggedIn && beacon) {
      beacon("prefill", {
        email: user.email,
      });
    }
  }, [user, beacon]);

  const open = React.useCallback(() => {
    if (beacon) {
      beacon("open");
    }
  }, [beacon]);

  return { open, error };
}

BeaconProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
