import React from "react";
import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../constants";
import api from "../../api";
import useClaimedBadges from "../useClaimedBadges";
import useEarnedBadges from "../useEarnedBadges";

const client = api();

export default function useBadgesQuery(options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: publishedBadges = [],
    isLoading: isBadgesLoading,
    isError: badgesError,
  } = useQuery([QUERY_KEYS.BADGES], client.getBadges, {
    staleTime: oneHour * 4,
    ...options,
  });
  const { earnedBadges } = useEarnedBadges();
  const { claimedBadges } = useClaimedBadges();

  // Store the initial badge order and badge IDs in refs
  const initialOrderRef = React.useRef(null);
  const previousBadgeIdsRef = React.useRef(new Set());

  const badges = React.useMemo(() => {
    const badgeMap = new Map();

    publishedBadges.forEach((badge) => {
      badgeMap.set(badge.id, { ...badge, isEarned: false, isClaimed: false });
    });

    earnedBadges.forEach((badge) => {
      if (badgeMap.has(badge.id)) {
        const existingBadge = badgeMap.get(badge.id);
        badgeMap.set(badge.id, {
          ...existingBadge,
          isEarned: badge.isEarned,
          isClaimed: badge.isClaimed,
        });
      } else {
        badgeMap.set(badge.id, badge);
      }
    });

    claimedBadges.forEach((badge) => {
      if (badgeMap.has(badge.id)) {
        const existingBadge = badgeMap.get(badge.id);
        badgeMap.set(badge.id, {
          ...existingBadge,
          isEarned: badge.isEarned,
          isClaimed: badge.isClaimed,
        });
      } else {
        badgeMap.set(badge.id, badge);
      }
    });

    const currentBadgeIds = new Set(badgeMap.keys());
    // Check if badges have been added or removed
    const badgesChanged =
      initialOrderRef.current === null ||
      // Check for any badge that exists now but didn't before
      Array.from(currentBadgeIds).some(
        (id) => !previousBadgeIdsRef.current.has(id),
      ) ||
      // Check for any badge that existed before but doesn't now
      Array.from(previousBadgeIdsRef.current).some(
        (id) => !currentBadgeIds.has(id),
      );

    // Update the previous badge IDs reference
    previousBadgeIdsRef.current = currentBadgeIds;

    const badgesArray = Array.from(badgeMap.values());

    if (badgesChanged) {
      const sortedBadges = [...badgesArray].sort((a, b) => {
        // Prioritize badges that can be claimed (isEarned: true)
        if (a.isEarned && !b.isEarned) return -1;
        if (!a.isEarned && b.isEarned) return 1;

        // For badges within the same group, sort by publishStartsAt (newest first)
        return new Date(b.publishStartsAt) - new Date(a.publishStartsAt);
      });

      // Save the new sort order
      initialOrderRef.current = new Map();
      sortedBadges.forEach((badge, index) => {
        initialOrderRef.current.set(badge.id, index);
      });

      return sortedBadges;
    }

    // For renders with the same badges but possible status changes,
    // maintain the previously established order
    return [...badgesArray].sort((a, b) => {
      const aIndex = initialOrderRef.current.get(a.id);
      const bIndex = initialOrderRef.current.get(b.id);

      // Use the established order
      return aIndex - bIndex;
    });
  }, [publishedBadges, earnedBadges, claimedBadges]);

  return { badges, isBadgesLoading, badgesError };
}
