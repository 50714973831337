import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { inBrowser } from "@gonoodle/gn-universe-utils";
import { validate } from "@gonoodle/gn-universe-analytics-schema";
import flowRight from "lodash/flowRight";

import BugsnagClient from "../../utils/bugsnag";
import { getAnalyticsParams } from "../../utils/analytics";
import { sendEvent } from "./core";

const JSURL = require("jsurl");

const AnalyticsContext = React.createContext();
AnalyticsContext.displayName = "AnalyticsContext";

export function getReferrerFromRouter(router) {
  if (inBrowser() && router.query.referrer) {
    return JSURL.parse(router.query.referrer);
  }
  return undefined;
}

function reportError({ properties, event, error }) {
  if (error && process.env.NODE_ENV === "development") {
    throw new Error(
      `Invalid ${event} event properties, ${error}. Received: ${JSON.stringify(
        properties,
      )}`,
    );
  } else if (error) {
    BugsnagClient.notify({
      name: "Missing analytics properties",
      message: `${event} event has missing properties, passed properties: ${
        properties ? JSON.stringify(properties) : undefined
      }, error: ${error}`,
    });
  }
  return {
    properties,
    event,
  };
}

export function AnalyticsProvider({
  children,
  options = {
    doNotTrack: false,
  },
}) {
  return (
    <AnalyticsContext.Provider value={options}>
      {children}
    </AnalyticsContext.Provider>
  );
}

function useAnalytics() {
  const context = React.useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error("You forgot to wrap your app in a AnalyticsProvider");
  }
  return context;
}

export function useLogEvent({
  event,
  properties,
  options = {
    doNotTrack: false,
  },
}) {
  const router = useRouter();
  const { enabled = false, referrer } = options;
  const sent = React.useRef(false);
  const { doNotTrack } = useAnalytics();

  const {
    mutateAsync: reportEvent,
    isIdle,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    ({ event: eventname, properties: eventAttributes }) =>
      sendEvent(eventname, eventAttributes),
    { retry: 3 },
  );

  const logEventWithValidation = flowRight(reportEvent, reportError, validate);

  // TODO: Make sure that logEvent is stable and doesn't change on every render.
  const logEvent = React.useCallback(
    (extraProperties = {}) => {
      if (!doNotTrack && event) {
        /**
         * Passing referrer as functions allow returning an object that has different shape than the fixed one returned by getAnalyticsParams.
         * Setting referrer as an object allow overriding the analytics params inside the query string.
         * Setting as a query string allow overriding the analytics params inside the query string.
         * If no referrer is set consume the query params.
         * getAnalyticsParams when invoked reads & returns the relevant analytics parameters.
         */
        return logEventWithValidation(event, {
          ...(typeof referrer === "function"
            ? referrer(getReferrerFromRouter(router))
            : getAnalyticsParams(
                referrer || getReferrerFromRouter(router) || router.query,
              )),
          ...properties,
          ...extraProperties,
        });
      }
      return undefined;
    },
    [doNotTrack, event, logEventWithValidation, properties, referrer, router],
  );

  React.useEffect(() => {
    if (enabled && sent.current === false) {
      sent.current = true;
      logEvent();
    }
  }, [enabled, logEvent]);
  return {
    logEvent,
    isIdle,
    isLoading,
    isSuccess,
    isError,
  };
}

AnalyticsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  options: PropTypes.shape({
    doNotTrack: PropTypes.bool,
  }),
};
